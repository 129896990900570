import {
  StandardAnalyticsActions,
  StandardAnalyticsControlTypes,
  createAnalytics
} from '@imtbl/react-analytics';
import { analyticsConfig } from '../utils';

type UserJourney = 'ViewPage' | 'ViewProduct' | 'WalletConnected';
type Control = string;
type ControlTypes = StandardAnalyticsControlTypes;
type Screen = string;
type Actions = StandardAnalyticsActions;

const { appName, writeKey } = analyticsConfig;
export const { AnalyticsProvider, useAnalytics } = createAnalytics<
  UserJourney,
  Screen,
  Control,
  ControlTypes,
  Actions
>({ writeKey, appName });
