import { config, passport as ImtblPassport } from '@imtbl/sdk';
import { useEffect, useState } from 'react';
import { notifyError, passportConfig } from '../utils';

export type usePassportClientProps = {
  environment: config.Environment;
};

export type UserInfo = Awaited<ReturnType<ImtblPassport.Passport['getUserInfo']>>;

export const usePassportClient = ({ environment }: usePassportClientProps) => {
  const { clientId, redirectUri, logoutRedirectUri } = passportConfig;
  const [passport, setPassport] = useState<ImtblPassport.Passport | undefined>(undefined);

  useEffect(() => {
    try {
      const passportInstance = new ImtblPassport.Passport({
        baseConfig: new config.ImmutableConfiguration({ environment }),
        clientId,
        redirectUri,
        logoutRedirectUri,
        popupOverlayOptions: {
          disableGenericPopupOverlay: true,
          disableBlockedPopupOverlay: false
        },
        audience: 'platform_api',
        scope: 'openid offline_access email transact'
      });

      setPassport(passportInstance);
    } catch (err) {
      notifyError(err);
    }
  }, [clientId, redirectUri, logoutRedirectUri, environment]);

  return passport;
};
