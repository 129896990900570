import * as crypto from 'crypto';
import { PRICING_FIXED_DECIMALS, storefrontType } from './env';
import { notifyError } from './errors';

export const isEmpty = (obj?: Record<string, any>) =>
  obj === null || obj === undefined || Object.keys(obj).length === 0;

export const safeJsonParse = (json: string) => {
  if (!json) return {};

  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};

export const delay = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const truncate = (address: string, length = 5) => {
  const start = address.slice(0, length);
  const end = address.slice(-length);
  return `${start}...${end}`;
};

export const readLocalStorage = (key: string) => {
  if (key === undefined) return '';

  try {
    const value = localStorage.getItem(key);
    return value ? safeJsonParse(value) : undefined;
  } catch (e) {
    typeof window !== undefined && notifyError(e);
  }
};

export const writeLocalStorage = (key: string, value: any) => {
  try {
    if (key === undefined) {
      notifyError(new Error('writeLocalStorage: key is undefined'));
      return;
    }

    if (value === undefined) {
      return localStorage.removeItem(key);
    }

    const safeValue = JSON.stringify(value);
    localStorage.setItem(key, safeValue);
  } catch (e) {
    typeof window !== undefined && notifyError(e);
  }
};

export const localStorageValue = (_key: string) => {
  const key = `@imtbl/commerce-${storefrontType}-${_key}`;
  return {
    write: (value: any) => writeLocalStorage(key, value),
    read: () => readLocalStorage(key),
    clear: () => writeLocalStorage(key, undefined)
  };
};

export const lsWalletProviderName = localStorageValue('wallet-provider-name');
export const lsReconnectingWallet = localStorageValue('reconnecting-wallet');

// create an md5 hash from a string and return a string
export const md5Hash = (buffer: string) => crypto.createHash('md5').update(buffer).digest('hex');

export const sanitizeToLatin1 = (str: string): string => {
  // match characters outside Latin1 (ISO-8859-1) range
  const regex = /[^\u0000-\u00FF]/g;
  return str.replace(regex, '');
};

export const toFormatedCurrency = (
  value: number | bigint | string,
  fixedTo = PRICING_FIXED_DECIMALS
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 3,
    minimumFractionDigits: fixedTo,
    maximumFractionDigits: fixedTo
  })
    .format(typeof value === 'string' ? parseFloat(value) : value)
    .replace('$', '');
};
