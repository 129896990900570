import { onDarkBase, onLightBase } from '@biom3/design-tokens';
import { fontJosefinSans, fontLitmuseum, fontRajdhani } from './fonts-config';
import { gogButtonSxOverride, metalcoreButtonSxOverride } from './style-overrides';
import { InputTheme, StorefrontTypes } from './types';

export const themes: Record<StorefrontTypes, InputTheme> = {
  [StorefrontTypes.METALCORE]: {
    base: {
      ...onDarkBase,
      color: {
        ...onDarkBase.color,
        accent: {
          1: '#00ffc1',
          2: '#70e9ca',
          3: '#c1b1a2',
          4: '#6f757c',
          5: '#596555',
          6: '#9ab8d5', // silver
          7: '#ddba00', // gold
          8: '#f47a49' // bronze
        },
        brand: {
          1: '#00ffc1',
          2: '#70e9ca',
          3: '#c1b1a2',
          4: '#6f757c',
          5: '#1f2b2b',
          6: '#fcfcfc'
        },
        fixed: {
          black: {
            100: '#02081114',
            1000: '#020811'
          },
          white: {
            100: '#ffffff14',
            1000: '#ffffff'
          }
        },
        neutral: {
          500: '#353535',
          600: '#2d2d2d',
          700: '#242424',
          800: '#1c1c1c',
          900: '#131313',
          1000: '#0b0b0b'
        },
        status: {
          attention: {
            bright: '#da6748',
            dim: '#e98267'
          },
          destructive: {
            bright: '#da6748',
            dim: '#e98267'
          },
          fatal: {
            bright: '#da6748',
            dim: '#e98267'
          },
          guidance: {
            bright: '#00ffc1',
            dim: '#00ffc1'
          },
          success: {
            bright: '#abf790',
            dim: '#d5fac7'
          }
        },
        text: {
          body: {
            primary: 'base.color.brand.6',
            secondary: 'base.color.brand.3',
            inverse: {
              primary: 'base.color.brand.5',
              secondary: 'base.color.brand.4'
            }
          },
          heading: {
            primary: 'base.color.fixed.white.1000',
            secondary: 'base.color.brand.2',
            emphasis: 'base.color.brand.3',
            inverse: {
              primary: 'base.color.brand.5',
              secondary: 'base.color.brand.4'
            }
          },
          caption: {
            primary: 'base.color.brand.6',
            secondary: 'base.color.brand.3',
            inverse: {
              primary: 'base.color.brand.5',
              secondary: 'base.color.brand.4'
            }
          },
          highlight: '#00ffc13d',
          link: {
            primary: 'base.color.accent.1',
            secondary: 'base.color.brand.6',
            inverse: {
              primary: 'base.color.accent.1',
              secondary: 'base.color.brand.5'
            }
          },
          status: {
            attention: {
              primary: 'base.color.status.attension.bright',
              secondary: 'base.color.status.attension.dim'
            },
            destructive: {
              primary: 'base.color.status.destructive.bright',
              secondary: 'base.color.status.destructive.dim'
            },
            fatal: {
              primary: 'base.color.status.fatal.bright',
              secondary: 'base.color.status.fatal.dim'
            },
            guidance: {
              primary: 'base.color.status.guidance.bright',
              secondary: 'base.color.status.guidance.dim'
            },
            success: {
              primary: 'base.color.status.success.bright',
              secondary: 'base.color.status.success.dim'
            }
          }
        },
        translucent: {
          emphasis: {
            100: '#137a60cc',
            200: '#70e9ca1f',
            300: '#70e9ca29',
            400: '#70e9ca2e',
            500: '#70e9cab3'
          },
          inverse: {
            50: '#3d464305',
            100: '#3d46430a',
            150: '#3d46430f',
            200: '#16161614',
            300: '#272727a3',
            400: '#1e1e1ea3',
            500: '#161616a3',
            600: '#0d0d0da3',
            700: '#050505a3',
            800: '#000000a3',
            900: '#3d4643e0',
            1000: '#020811'
          },
          standard: {
            100: '#bfc2c20a',
            150: '#bfc2c214',
            200: '#bfc2c21a',
            300: '#bfc2c21f',
            400: '#bfc2c229',
            50: '#bfc2c205',
            500: '#bfc2c23d',
            600: '#bfc2c27a',
            700: '#bfc2c2a3',
            800: '#bfc2c2cc',
            900: '#bfc2c2e0',
            1000: '#bfc2c2'
          }
        }
      },
      font: {
        ...onDarkBase.font,
        family: {
          ...onDarkBase.font.family,
          heading: {
            primary: fontRajdhani.style.fontFamily,
            secondary: fontRajdhani.style.fontFamily
          }
        }
      },
      gradient: {
        ...onDarkBase.gradient,
        '2': {
          ...onDarkBase.gradient['2'],

          spectrum: 'linear-gradient(105.76deg, #0d0d0d7a -46.96%, #0d0d0d7a 99.99%)'
        }
      }
    },
    components: {
      Button: {
        sxOverride: {
          ...metalcoreButtonSxOverride
        }
      },
      Banner: {
        sxOverride: {
          brad: 'base.borderRadius.x1',
          justifyContent: 'center',
          alignItems: 'flex-start',
          '& .BannerIcon': {
            position: 'relative',
            top: '0',
            left: '0'
          },
          '& .Button': {
            brad: '0',
            '&::before': {
              clipPath: 'unset',
              boxShadow: 'unset !important'
            },
            '&:hover:not([disabled])': {
              clipPath: 'unset',
              boxShadow: 'unset !important',
              bg: 'base.color.neutral.600',
              '&::before': {
                brad: '0',
                border: 'none',
                bg: 'base.color.neutral.600'
              }
            }
          },
          '& .Button--tertiary\\/inverse:hover:not([disabled])': {
            color: 'base.color.fixed.white.1000',
            '&::before': {
              color: 'base.color.fixed.white.1000'
            }
          }
        }
      }
    }
  },
  [StorefrontTypes.SHARDBOUND]: {
    base: {
      ...onLightBase,
      color: {
        ...onLightBase.color,
        accent: {
          1: '#ffa000',
          2: '#fa0400',
          3: '#ffd720',
          4: '#d2f970',
          5: '#50ebfe',
          6: '#d8eff4',
          7: '#cc77f8',
          8: '#f15723'
        },
        brand: {
          1: '#f15719',
          2: '#813620',
          3: '#957630',
          4: '#24baa4',
          5: '#454545',
          6: '#b5b5b5'
        },
        fixed: {
          black: {
            100: '#15151514',
            1000: '#151515'
          },
          white: {
            100: '#f5f5f514',
            1000: '#f5f5f5'
          }
        },
        neutral: {
          1000: '#ffffff',
          500: '#f5f5f5',
          600: '#f5f5f5',
          700: '#f5f5f5',
          800: '#ffffff',
          900: '#ffffff'
        },
        status: {
          attention: {
            bright: '#ffd76e',
            dim: '#ffd720'
          },
          destructive: {
            bright: '#fa0400',
            dim: '#fa0400'
          },
          fatal: {
            bright: '#ff9064',
            dim: '#ffd2a8'
          },
          guidance: {
            bright: '#00ffc1',
            dim: '#99ffe6 '
          },
          success: {
            bright: '#00ffc1',
            dim: '#99ffe6'
          }
        },
        text: {
          body: {
            primary: 'base.color.brand.5',
            secondary: 'base.color.brand.',
            inverse: {
              primary: 'base.color.neutral.900',
              secondary: 'base.color.brand.6'
            }
          },
          caption: {
            primary: 'base.color.brand.5',
            secondary: '#656565',
            inverse: {
              primary: 'base.color.neutral.900',
              secondary: 'base.color.brand.6'
            }
          },
          heading: {
            primary: 'base.color.brand.2',
            secondary: 'base.color.brand.3',
            emphasis: 'base.color.brand.1',
            inverse: {
              primary: 'base.color.neutral.900',
              secondary: 'base.color.neutral.900'
            }
          },
          link: {
            primary: 'base.color.accent.7',
            secondary: 'base.color.brand.5',
            inverse: {
              primary: 'base.color.accent.7',
              secondary: 'base.color.neutral.900'
            }
          },
          highlight: '#f1572329',
          status: {
            attention: {
              primary: 'base.color.status.attension.bright',
              secondary: 'base.color.status.attension.dim'
            },
            destructive: {
              primary: 'base.color.status.destructive.bright',
              secondary: 'base.color.status.destructive.dim'
            },
            fatal: {
              primary: 'base.color.status.fatal.bright',
              secondary: 'base.color.status.fatal.dim'
            },
            guidance: {
              primary: 'base.color.status.guidance.bright',
              secondary: 'base.color.status.guidance.dim'
            },
            success: {
              primary: 'base.color.status.success.bright',
              secondary: 'base.color.status.success.dim'
            }
          }
        },
        translucent: {
          emphasis: {
            100: '#1515150a',
            200: '#1515150f',
            300: '#15151514',
            400: '#1515151a',
            500: '#1515151f'
          },
          inverse: {
            50: '#f5f5f505',
            100: '#f5f5f50a',
            150: '#f5f5f50F',
            200: '#f5f5f514',
            300: '#f5f5f51f',
            400: '#f5f5f529',
            500: '#f5f5f53d',
            600: '#f5f5f57a',
            700: '#f5f5f5a3',
            800: '#f5f5f5cc',
            900: '#f5f5f5e0',
            1000: '#f5f5f5'
          },
          standard: {
            50: '#15151505',
            100: '#1515150a',
            150: '#15151514',
            200: '#1515151a',
            300: '#1515151f',
            400: '#15151529',
            500: '#1515153d',
            600: '#1515157a',
            700: '#151515a3',
            800: '#151515cc',
            900: '#151515e0',
            1000: '#151515'
          }
        }
      },
      font: {
        ...onLightBase.font,
        family: {
          ...onLightBase.font.family,
          heading: {
            primary: fontJosefinSans.style.fontFamily,
            secondary: fontJosefinSans.style.fontFamily
          }
        }
      },
      gradient: {
        ...onDarkBase.gradient,
        '2': {
          ...onDarkBase.gradient['2'],
          spectrum: 'linear-gradient(105.76deg, #f191fa4d -46.96%, #83e3f04d 99.99%)'
        }
      }
    }
  },
  [StorefrontTypes.SAMPLE_STORE]: {
    base: {
      ...onLightBase,
      color: {
        ...onLightBase.color,
        accent: {
          1: '#ffa000',
          2: '#fa0400',
          3: '#ffd720',
          4: '#d2f970',
          5: '#50ebfe',
          6: '#d8eff4',
          7: '#cc77f8',
          8: '#f15723'
        },
        brand: {
          1: '#f15719',
          2: '#813620',
          3: '#957630',
          4: '#24baa4',
          5: '#454545',
          6: '#b5b5b5'
        },
        fixed: {
          black: {
            100: '#15151514',
            1000: '#151515'
          },
          white: {
            100: '#f5f5f514',
            1000: '#f5f5f5'
          }
        },
        neutral: {
          1000: '#ffffff',
          500: '#f5f5f5',
          600: '#f5f5f5',
          700: '#f5f5f5',
          800: '#ffffff',
          900: '#ffffff'
        },
        status: {
          attention: {
            bright: '#ffd76e',
            dim: '#ffd720'
          },
          destructive: {
            bright: '#fa0400',
            dim: '#fa0400'
          },
          fatal: {
            bright: '#ff9064',
            dim: '#ffd2a8'
          },
          guidance: {
            bright: '#00ffc1',
            dim: '#99ffe6 '
          },
          success: {
            bright: '#00ffc1',
            dim: '#99ffe6'
          }
        },
        text: {
          body: {
            primary: 'base.color.brand.5',
            secondary: 'base.color.brand.',
            inverse: {
              primary: 'base.color.neutral.900',
              secondary: 'base.color.brand.6'
            }
          },
          caption: {
            primary: 'base.color.brand.5',
            secondary: '#656565',
            inverse: {
              primary: 'base.color.neutral.900',
              secondary: 'base.color.brand.6'
            }
          },
          heading: {
            primary: 'base.color.brand.2',
            secondary: 'base.color.brand.3',
            emphasis: 'base.color.brand.1',
            inverse: {
              primary: 'base.color.neutral.900',
              secondary: 'base.color.neutral.900'
            }
          },
          link: {
            primary: 'base.color.accent.7',
            secondary: 'base.color.brand.5',
            inverse: {
              primary: 'base.color.accent.7',
              secondary: 'base.color.neutral.900'
            }
          },
          highlight: '#f1572329',
          status: {
            attention: {
              primary: 'base.color.status.attension.bright',
              secondary: 'base.color.status.attension.dim'
            },
            destructive: {
              primary: 'base.color.status.destructive.bright',
              secondary: 'base.color.status.destructive.dim'
            },
            fatal: {
              primary: 'base.color.status.fatal.bright',
              secondary: 'base.color.status.fatal.dim'
            },
            guidance: {
              primary: 'base.color.status.guidance.bright',
              secondary: 'base.color.status.guidance.dim'
            },
            success: {
              primary: 'base.color.status.success.bright',
              secondary: 'base.color.status.success.dim'
            }
          }
        },
        translucent: {
          emphasis: {
            100: '#1515150a',
            200: '#1515150f',
            300: '#15151514',
            400: '#1515151a',
            500: '#1515151f'
          },
          inverse: {
            50: '#f5f5f505',
            100: '#f5f5f50a',
            150: '#f5f5f50F',
            200: '#f5f5f514',
            300: '#f5f5f51f',
            400: '#f5f5f529',
            500: '#f5f5f53d',
            600: '#f5f5f57a',
            700: '#f5f5f5a3',
            800: '#f5f5f5cc',
            900: '#f5f5f5e0',
            1000: '#f5f5f5'
          },
          standard: {
            50: '#15151505',
            100: '#1515150a',
            150: '#15151514',
            200: '#1515151a',
            300: '#1515151f',
            400: '#15151529',
            500: '#1515153d',
            600: '#1515157a',
            700: '#151515a3',
            800: '#151515cc',
            900: '#151515e0',
            1000: '#151515'
          }
        }
      },
      font: {
        ...onLightBase.font,
        family: {
          ...onLightBase.font.family,
          heading: {
            primary: fontJosefinSans.style.fontFamily,
            secondary: fontJosefinSans.style.fontFamily
          }
        }
      },
      gradient: {
        ...onDarkBase.gradient,
        '2': {
          ...onDarkBase.gradient['2'],
          spectrum: 'linear-gradient(105.76deg, #f191fa4d -46.96%, #83e3f04d 99.99%)'
        }
      }
    }
  },
  [StorefrontTypes.GOG]: {
    base: {
      ...onDarkBase,
      color: {
        ...onDarkBase.color,
        accent: {
          ...onDarkBase.color.accent,
          1: '#f9ce80',
          2: '#f9f4d6',
          3: '#e2d8e9',
          4: '#161922',
          5: '#f1f3f5',
          6: '#cd973d'
        },
        brand: {
          ...onDarkBase.color.brand,
          1: '#f9ce80',
          2: '#f9f4d6',
          3: '#e2d8e9',
          4: '#161922',
          5: '#f1f3f5',
          6: '#684d22'
        },
        neutral: {
          ...onDarkBase.color.neutral,
          700: 'base.color.translucent.inverse.800', // card hover
          800: 'base.color.translucent.inverse.900', // card active
          900: 'base.color.translucent.inverse.700', // card background
          1000: '#06142d'
        },
        text: {
          ...onDarkBase.color.text,
          body: {
            ...onDarkBase.color.text.body,
            primary: 'base.color.fixed.white.1000',
            secondary: 'base.color.fixed.white.1000',
            inverse: {
              primary: 'base.color.brand.4',
              secondary: 'base.color.brand.4'
            }
          },
          heading: {
            ...onDarkBase.color.text.heading,
            primary: 'base.color.fixed.white.1000',
            secondary: 'base.color.fixed.white.1000',
            emphasis: 'base.color.fixed.white.1000'
          },
          link: {
            ...onDarkBase.color.text.link,
            primary: 'base.color.accent.1'
          },
          caption: {
            ...onDarkBase.color.text.caption,
            primary: 'base.color.brand.3'
          }
        },
        translucent: {
          ...onDarkBase.color.translucent,
          emphasis: {
            ...onDarkBase.color.translucent.emphasis,
            300: 'base.color.translucent.inverse.800', // card caption
            500: 'base.color.translucent.inverse.600' // button secondary
          }
        }
      },

      font: {
        ...onDarkBase.font,
        family: {
          ...onDarkBase.font.family,
          heading: {
            primary: fontLitmuseum.style.fontFamily,
            secondary: fontLitmuseum.style.fontFamily
          }
        }
      },
      gradient: {
        ...onDarkBase.gradient,
        1: {
          ...onDarkBase.gradient['1'],
          spectrum: 'linear-gradient(105.76deg, #f9ce80 -46.96%, #e2d8e9 99.99%)'
        },
        '2': {
          ...onDarkBase.gradient['2'],
          spectrum:
            'linear-gradient(180deg, rgba(28, 2, 14, 0.6) 8.21%, rgba(16, 0, 24, 0.216) 76.78%)'
          // 'linear-gradient(180deg, #00000042 30%, #00000052 55%, #00000037 65%, #00000099 90%)'
        }
      }
    },
    components: {
      Button: {
        sxOverride: {
          ...gogButtonSxOverride
        }
      }
    }
  }
};
