import type { AppProps } from 'next/app';

import { useReportWebVitals } from 'next/web-vitals';
import { AnalyticsProvider } from 'src/context/analytics-context';
import { ThemeProvider } from 'src/context/theme-context';
import { WidgetsProvider } from 'src/context/widgets-context';
import { recordMetric } from 'src/utils/metrics';

export default function MyApp({ Component, pageProps }: AppProps) {
  useReportWebVitals((metric) => {
    recordMetric(metric.name, metric.value);
  });

  return (
    <ThemeProvider>
      <AnalyticsProvider>
        <WidgetsProvider>
          <Component {...pageProps} />
        </WidgetsProvider>
      </AnalyticsProvider>
    </ThemeProvider>
  );
}
