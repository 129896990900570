import { checkout as ImtblCheckout, config as ImtblConfig } from '@imtbl/sdk';
import getConfig from 'next/config';
import { StorefrontTypes } from '../theme/types';

const { publicRuntimeConfig: envConfig = {} } = getConfig() || {};

export const WALLET_WIDGETS_ROOT = 'WALLET_WIDGETS_ROOT';
export const SALE_WIDGET_ROOT = 'SALE_WIDGET_ROOT';

export const environment =
  envConfig.STOREFRONT_ENV === 'production'
    ? ImtblConfig.Environment.PRODUCTION
    : ImtblConfig.Environment.SANDBOX;

export const isProduction = environment === ImtblConfig.Environment.PRODUCTION;

const PASSPORT_DASHBOARD_URL = {
  [ImtblConfig.Environment.PRODUCTION]: 'https://passport.immutable.com',
  [ImtblConfig.Environment.SANDBOX]: 'https://passport.sandbox.immutable.com'
};

export const passportConfig = {
  clientId: envConfig.PASSPORT_CLIENT_ID,
  redirectUri: envConfig.PASSPORT_REDIRECT_URI,
  logoutRedirectUri: envConfig.PASSPORT_LOGOUT_REDIRECT_URI
};

export const analyticsConfig = {
  writeKey: envConfig.ANALYTICS_WRITE_KEY,
  appName: envConfig.STOREFRONT
};

type SaleWidgetConfig = Pick<ImtblCheckout.SaleWidgetParams, 'environmentId' | 'language'>;
export const saleWidgetConfig: SaleWidgetConfig = {
  language: 'en',
  environmentId: envConfig.SALE_WIDGET_ENVIRONMENT
};

export const gameConfig = {
  gameClientUrl: envConfig.GAME_CLIENT_URL || '#',
  gameFaqsUrl: envConfig.GAME_FAQS_URL || '#',
  passportUrl: PASSPORT_DASHBOARD_URL[environment]
};

export const storefrontType = envConfig.STOREFRONT as StorefrontTypes;

export const storefrontName = envConfig.STOREFRONT_NAME || 'Immutable Store';

export const companyName = envConfig.COMPANY_NAME || 'Immutable';

export const graphQLServerURL = envConfig.WOOCOMERCE_GRAPHQL || '';

export const newRelicAppName = envConfig.NEW_RELIC_APP_NAME || '';

// maximum quantity of items that can be added to the cart in a single transaction by storefront
const STORE_MAX_QUANTITY_BY_STOREFRONT: Record<StorefrontTypes, { crypto: number; fiat: number }> =
  {
    [StorefrontTypes.GOG]: {
      fiat: 350,
      crypto: 1000
    },
    [StorefrontTypes.METALCORE]: {
      fiat: 350,
      crypto: 1000
    },
    [StorefrontTypes.SHARDBOUND]: {
      fiat: 350,
      crypto: 1000
    },
    [StorefrontTypes.SAMPLE_STORE]: {
      fiat: 350,
      crypto: 1000
    }
  };

// maximum quantity of items that can be added to the cart in a single transaction
export const STORE_MAX_QUANTITY = STORE_MAX_QUANTITY_BY_STOREFRONT[storefrontType];

// rounding decimals for pricing
export const PRICING_FIXED_DECIMALS = 2;

export const walletConnectConfig: ImtblCheckout.WalletConnectConfig | undefined =
  !!envConfig.WALLET_CONNECT_PROJECT_ID
    ? {
        projectId: envConfig.WALLET_CONNECT_PROJECT_ID,
        metadata: {
          name: storefrontName,
          description: `Buy items on ${storefrontName}`,
          icons: [envConfig.WALLET_CONNECT_ICON_URL],
          url: envConfig.WALLET_CONNECT_URL
        }
      }
    : undefined;
